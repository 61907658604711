@import './styles/color';
@import './styles/base';
@import './styles/fonts';

.app {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  display: flex;
  justify-content: center;
}

.shell {
  background-color: $base-color;
  display: flex;
  align-self: center;
  box-shadow: 1px 3px 20px 8px rgba(0, 0, 0, 0.09);
  height: inherit;
  height: 667px;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  position: relative;

  @media screen and (min-width: 380px) {
    border-radius: 10px;
    width: 375px;
  }
}

.page-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

button, a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
