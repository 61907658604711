@font-face {
  font-family: 'Sarabun';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local('Sarabun Regular'), local('Sarabun-Regular'), url(https://fonts.gstatic.com/s/sarabun/v6/DtVjJx26TKEr37c9aBVJnw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Sarabun';
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: local('Sarabun ExtraBold'), local('Sarabun-ExtraBold'), url(https://fonts.gstatic.com/s/sarabun/v6/DtVmJx26TKEr37c9YLJvilss6w.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
