.page {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0 ;
  left: 0;
  transform: translate3d(0,0,0);
  transition: transform .3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &.unactive {
    pointer-events: none;
    transform: translateX(100%) translate3d(0,0,0);
  }


  &.active {
    pointer-events: visible;
    transform: translateX(0) translate3d(0,0,0);
  }
}
