$main-gradient: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);

$base-color: #fff;
$primary-font-color: #585858;
$secondary-font-color: #5d5555;
$tertiary-font-color: #1f54a2;
$primary-icon-bg-color: #dadada3d;
$secondary-icon-bg-color: #b5b4b43d;
$border-color: #7db0f2a1;
$primary-highlight-color: #92a0e6;
$secondary-highlight-color: #7b80d2;
$list-hover: #c1c1c1;
$list-active: #ececec;
$list-press: #f1f1f1;
